import React from "react"
import tw from "twin.macro"
import { ContactForm } from "../components/contact-form"
import { Banner } from "../components/banner"
import Image from "../components/image"

const Mail = "mail.png"

const Container = tw.div`
    max-w-screen-xl
    flex
    flex-col
    justify-center
    m-auto
    w-full
`

const ContentContainer = tw.div`
    flex
    flex-wrap
    px-page-gutter
    flex-col-reverse
    w-full
    lg:-mb-64
    z-10
    md:flex-row
`

const PAGE_CONSTANTS = {
  title: "Contact",
  header: "Contact Us",
  description:
    "Contact us for a small business website at $0 down, $150 a month. We make affordable websites for small businesses.",
}

const ContactPage = () => {
  return (
    <Container>
      <Banner
        title={PAGE_CONSTANTS.title}
        header={PAGE_CONSTANTS.header}
        desctiption={PAGE_CONSTANTS.description}
      />
      <ContentContainer>
        <div className="md:w-1/2">
          <ContactForm />
        </div>
        <div className="w-full mb-10 md:w-1/2">
          <Image src={Mail} />
        </div>
      </ContentContainer>
    </Container>
  )
}

export default ContactPage
